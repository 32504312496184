import React from "react";
import { Button, Row, Col } from "react-bootstrap";

import ReactMarkdown from "react-markdown";

const ResultsDetails = ({ results }) => {

  function getDomainFromUrl(url) {
    try {
      return new URL(url).hostname;
    } catch (error) {
      console.error(`Invalid URL: ${url}`);
      return null;
    }
  }

  return (
    <>
      <Row
        style={{ background: "#C2DDE0" }}
        className="justify-content-md-center"
      >
        <Col> </Col>
        <Col xs={7} className="py-4">
          <div className="text-center">
          More customers will buy if you align your marketing to their values
          </div>
          <div className="text-center fs-2 py-4">{getDomainFromUrl(results.url)}</div>
          <div className="text-center lh-base">
            <ReactMarkdown>{results.summary}</ReactMarkdown>
          </div>
          <div className="text-center fs-5 py-4">What Customers See</div>
          <div className="lh-lg">
            <ReactMarkdown>{results.what_customers_see}</ReactMarkdown>
          </div>
          <div className="text-center mt-5">
            We'll show you how to expand alignment to drive new growth. 
          </div>
          <div className="d-flex justify-content-center py-2">
            <a href="https://www.lifemind.ai/contact-us"
              style={{ background: "#035772", border: 0, fontSize: "14px" }}
              className="py-3 px-5 text-white"
            >
              Set Up Introductory Call
            </a>
          </div>
        </Col>
        <Col> </Col>
      </Row>
      <Row
        style={{ background: "#FFFFFF" }}
        className="justify-content-md-center"
      >
        <Col> </Col>
        <Col xs={9} className="py-4">
          <div className="text-center fs-5">Life Outlook Analysis</div>
          <div className="d-flex justify-content-center">
            <img
              src={process.env.PUBLIC_URL + "/images/img-1.png"}
              width="700px"
              alt="Lifemind"
            />
          </div>
          <div className="my-4 lh-lg">
            <ReactMarkdown>{results.life_outlook}</ReactMarkdown>
          </div>
          <div className="my-4"><strong>Why It Matters</strong></div>
          <div className="lh-lg">
            Life Outlook is the measure of your marketing as leaning Egalitarian
            or Positional, terms that originate in social anthropology.
            Egalitarian is loosely correlated to liberal while Positional is
            loosely correlated to conservative. Each group has distinct values
            used to evaluate and make purchase decisions.
            <br />
            <br />
            The national market is split down the middle between these two
            groups and they have similar spending power. Knowing the skew of
            your marketing enables you to create far stronger business-market
            fit with one or both groups. We'll show you how. 
          </div>
          <div className="text-center fs-4 my-5">Regional Analysis</div>
          <div className="d-flex justify-content-center">
            <img
              src={process.env.PUBLIC_URL + "/images/img-2.png"}
              width="700px"
              alt="Lifemind"
            />
          </div>
          <div className="my-4 lg">
            <ReactMarkdown>{results.output_regions_analysis}</ReactMarkdown>
          </div>
          <div className="my-4"><strong>Why It Matters</strong></div>
          <div className="lh-lg">
            Lifemind uses a proprietary regional map based on distinct values
            sets derived from DNA data, Census data, and historical research. By
            combining Life Outlook and Regional values in your marketing, you
            can sell more to your current customers and expand your market
            through stronger alignment where it's weaker. We'll show you how. 
          </div>
          <div className="text-center fs-4 my-5">Generational Analysis</div>
          <div className="d-flex justify-content-center">
            <img
              src={process.env.PUBLIC_URL + "/images/img-3.png"}
              width="700px"
              alt="Lifemind"
            />
          </div>
          <div className="my-4 lg">
            <ReactMarkdown>{results.generation_output}</ReactMarkdown>
          </div>
          <div className="my-4"><strong>Why It Matters</strong></div>
          <div className="lh-lg">
            The national market is almost equally divided in population between
            GenZ, GenY, GenX, and Boomers. You can expand your market to sell to
            customers not buying from you now by segmenting your marketing with
            lifemind. 
          </div>
          <div className="d-flex justify-content-center py-2">
          <a href="https://www.lifemind.ai/contact-us"
              style={{ background: "#035772", border: 0, fontSize: "14px" }}
              className="py-3 px-5 text-white"
            >
              Set Up Introductory Call
            </a>
          </div>
        </Col>
        <Col> </Col>
      </Row>
    </>
  );
};

export default ResultsDetails;
