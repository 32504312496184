import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingScreen = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(150, 200, 200, 0.95)",
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <Spinner
        animation="border"
        role="status"
        style={{ marginBottom: "20px" }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <h2>Thanks for submitting!</h2>
      <p>
        Lifemind has already started analyzing your website to create your
        personalized Market Alignment Assessment. What would have taken weeks
        not too long ago will now take approximately 1 to 3 minutes.
      </p>
      <p>
        Your Market Alignment Assessment will provide you with some of the key
        values projected by your website - and by default your company. Better
        aligning your company values with those of your customers will result in
        stronger sales.
      </p>
      <p>
        Please be patient. Don't close this window. You'll be whisked away to
        your Assessment as soon as it's ready!
      </p>
      <p><i>Please note that some company websites block AI and make it impossible for Lifemind to provide our Analysis.</i></p>
    </div>
  );
};

export default LoadingScreen;
