import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import api from "../utils/api";

import ResultsDetails from "./ResultsDetails";
import LoadingScreen from "./LoadingScreen";

const Results = () => {
  let { id } = useParams();

  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [crawlError, setCrawlError] = useState(false);

  let attemptCount = 0;

  const fetchResults = async () => {
    try {
      const response = await api.get(`/demo/results?checksum=${id}`);
      console.log(response.data.status);
      console.log(attemptCount);
      if (response.data.status === "completed" || attemptCount > 10) {
        setResults(response.data);
        setIsCompleted(true);
      } else if (response.data.status === "started") {
        attemptCount++;
        setTimeout(fetchResults, 30000); // Call the function again after 30 seconds
      } else {
        setCrawlError(true);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <img
            src={process.env.PUBLIC_URL + "/logo-lifemind.png"}
            width="250px"
            alt="Lifemind"
          />
        </Col>
      </Row>

      <div style={{ fontFamily: "Montserrat" }}>
        {!isCompleted && !crawlError && <LoadingScreen />}
        {isCompleted && !crawlError && <ResultsDetails results={results} />}
        {crawlError && (
          <div>
            <h1>Oops! We couldn't analyze your website.</h1>
            <p>
              Due to constraints on the website domain you shared, we are unable
              to deliver the analysis. Please visit www.lifemind.ai to learn how
              aligning your communications with your customers’ Worldviews can
              accelerate your business growth.
              <br />
              <br />
              We apologize for the inconvenience.
              <br />
              <br />
              This could be due to a number of reasons, including:
            </p>
            <ul>
              <li>Your website is blocking our analysis tool.</li>
              <li>Your website is not accessible from the public internet.</li>
              <li>Your website is not a valid website address (URL).</li>
            </ul>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center">
        Privacy Policy | Copyright 2024 Lifemind llc. All Rights Reserved.
      </div>
    </Container>
  );
};

export default Results;
