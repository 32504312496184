import React, { useState, useEffect } from "react";
import {
  InputGroup,
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Spinner,
} from "react-bootstrap";

import api from "../utils/api";

const Homepage = () => {
  const [validated, setValidated] = useState(false);

  const [website, setWebsite] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  const [websiteError, setWebsiteError] = useState("");
  const [firstnameError, setFirstnameError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [learnWebsite, setLearnWebsite] = useState();
  const [signedUp, setSignedUp] = useState(false);

  function validateAndFormatUrl(url) {
    let newUrl = url;

    // Add "https://" if missing
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      newUrl = "https://" + url;
    }

    // Create a new URL object to validate the URL
    try {
      new URL(newUrl);
      return newUrl;
    } catch (err) {
      throw new Error("Invalid URL");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      validateAndFormatUrl(website);
      setWebsiteError(""); // Clear any previous error message
      setValidated(true);
    } catch (error) {
      setWebsiteError("Invalid URL");
      return; // Don't submit the form if the URL is invalid
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setSignedUp(true);
    setIsLoading(true);

    let data = new FormData();
    data.append("firstname", firstname);
    data.append("lastname", lastname);
    data.append("email", email);
    data.append("company", company);

    console.log(data);

    // Call the API to start the assessment
    //const response = await api.post(`/demo/research?url=${encodeURIComponent(website)}`);
    //console.log(response.data);
    //setLearnWebsite(response.data.demo.learn_website);
    //setIsLoading(false);
    //setWebsite("");
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <img src={process.env.PUBLIC_URL + "/logo-lifemind.png"} width="250px" alt="Lifemind" />
        </Col>
      </Row>

      <div className="p-5 mb-4 bg-body-tertiary border rounded-3">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Lifemind Free Assessment</h1>
          <div className="row">
            <div className="col-md-8">
              <p className="fs-4">Why aren't more customers buying from you?</p>
              <ul>
                <li>What your market sees when looking at your website</li>
                <li>How your site aligns with national market</li>
                <li>What changes can expand your appeal to more customers</li>
              </ul>
            </div>
            <div className="col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/images/report-example.png"}
                className="img-thumbnail float-right"
                style={{ transform: "rotate(10deg)", height: "200px" }}
                alt="..."
              />
            </div>
          </div>

          {isLoading && (
            <>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <Alert variant="success">
                {" "}
                You're Assessment has started. Once finalized you'll be
                automatically redirected to the result page{" "}
              </Alert>
            </>
          )}

          {!isLoading && !validated && (
            <>
              <h4 className="py-4">Enter your website to get started</h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <InputGroup className="mb-3" controlId="website">
                <Form.Control.Feedback type="invalid">
                    {websiteError}
                  </Form.Control.Feedback>
                  <Form.Control
                    type="text"
                    placeholder="Enter website URL"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    isInvalid={!!websiteError}
                  />
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Get started"}
                  </Button>
                </InputGroup>
              </Form>
            </>
          )}

          {!isLoading && validated && !signedUp && (
            <div className="container-fluid py-5">
              <p>To access your free Assessment, enter your details:</p>
              <Form noValidate validated={validated} onSubmit={handleSignup}>
                <Row>
                  <Col>
                    <Form.Control
                      placeholder="First name"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      isInvalid={!!firstnameError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {firstnameError}
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Control
                      placeholder="Last name"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      isInvalid={!!websiteError}
                    />
                  </Col>
                </Row>
                <Form.Control
                  className="mt-3"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={!!websiteError}
                />
                <Form.Control
                  className="mt-3"
                  type="company"
                  placeholder="Company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  isInvalid={!!websiteError}
                />
                <Button className="mt-3" variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>

      {signedUp && (
        <>
          <h2>Thank you for signing up. Here's your free Assessment</h2>
          <p>{learnWebsite}</p>
        </>
      )}
    </Container>
  );
};

export default Homepage;
